
import { mapGetters } from "vuex";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      images: [],
      titles: [],
      titleUrls: [],
      sourceButtons: [],
      galleryType: "",
      currentImageIndex: 0,
      closed: true,
      handlers: {},
      thumbnails: false,
      galleryTypes: [],

      // VueSlickCarousel setting: https://gs-shop.github.io/vue-slick-carousel/#/api
      settings: {
        centerMode: true,
        variableWidth: true,
        accessibility: true,
        speed: 400,
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: "profiles/getProfile",
    }),
    additionalImages() {
      const gi =
        this.galleryTypes[0] == "profile-gallery"
          ? "galleryFirst"
          : "gallerySecond";
      return this.profile?.[gi]?.map((i) => i.src) || [];
    },
    allImages() {
      return [...this.images, ...this.additionalImages];
    },
    allGalleryTypes() {
      const at =
        this.galleryTypes[0] == "profile-gallery"
          ? "profile-selfie"
          : "profile-gallery";
      const agt = this.additionalImages.map(() => at);
      return [...this.galleryTypes, ...agt];
    },
    // profileName() {
    //   return this.profile?.name || "";
    // },
    // currentImage() {
    //   return this.images[this.currentImageIndex];
    // },
  },
  watch: {
    closed(newVal) {
      if (newVal && this.handlers.closed) {
        this.handlers.closed();
      }
      if (!newVal && this.handlers.opened) {
        this.$refs?.slick?.goTo(this.currentImageIndex, true);
        this.handlers.opened();
      }
    },
  },
  created() {
    if (!window) return;
    window.addEventListener("keyup", (e) => {
      // esc key and 'q' for quit
      if (e.key === "Escape" || e.key === "q") this.close();
      // arrow right and 'l' key (vim-like binding)
      if (e.key === "ArrowRight" || e.key === "l") this.next();
      // // arrow left and 'h' key (vim-like binding)
      if (e.key === "ArrowLeft" || e.key === "h") this.prev();
    });
    // window.addEventListener("scroll", () => {
    //   this.close();
    // });
  },

  mounted() {
    this.setSlickProps();
  },

  methods: {
    slickInited() {
      this.$nextTick(() => {
        this.$refs?.slick?.goTo(this.currentImageIndex, true);
        this.setSlickProps();
        if (this.$refs?.slick?.$refs && this.$refs?.slick?.$refs?.innerSlider) {
          this.$refs.slick.$refs.innerSlider.$props.verticalSwiping = true;
        }
      });
    },

    setSlickProps() {
      if (window.innerWidth < 768) {
        this.settings.vertical = true;
        this.settings.swipeToSlide = true;
      }
    },
    // Not using currentImageIndex watcher because it will
    // fire on all cases when opened not first image and
    // index should be changed in order to show clicked image
    // in the image set.
    fireChangeEvent() {
      if (this.handlers.changed) {
        this.handlers.changed(this.currentImageIndex);
      }
    },
    close() {
      if (!this.closed) {
        document.querySelector("body").classList.remove("body-fs-v-img");
        this.images = [];
        this.currentImageIndex = 0;
        this.closed = true;
      }
    },
    next() {
      if (!this.closed && this.allImages.length > 1) {
        // if next index not exists in array of images, set index to first element
        if (this.currentImageIndex + 1 < this.allImages.length) {
          this.currentImageIndex++;
        } else {
          this.currentImageIndex = 0;
        }
        this.$refs?.slick?.next();
        this.fireChangeEvent();
      }
    },
    select(selectedImage) {
      this.currentImageIndex = selectedImage;
      this.$refs?.slick?.goTo(selectedImage);
      this.fireChangeEvent();
    },
    prev() {
      if (!this.closed && this.allImages.length > 1) {
        // if prev index not exists in array of images, set index to last element
        if (this.currentImageIndex > 0) {
          this.currentImageIndex--;
        } else {
          this.currentImageIndex = this.allImages.length - 1;
        }
        this.$refs?.slick?.prev();
        this.fireChangeEvent();
      }
    },
  },
};
