// [pattern, replacement]
const removeExtraSymbolAfterSpace = ["\\s+[,:;]", ""];
const removeCommaBeforePunctuation = [",([.!?:;])", "$1"];
const removeMultipleSymbols = ["([.!?:,;])\\1+", "$1"];
const removeMultipleSpaces = ["\\s{2,}", " "];

// Default config for cleanupText(postprocessing after replacing placeholders)
const defaultTemplateProcessorConfig = {
  replacements: [
    removeExtraSymbolAfterSpace,
    removeCommaBeforePunctuation,
    removeMultipleSymbols,
    removeMultipleSpaces,
  ],
  trim: true,
};

/**
 * Fill placeholders like {key} with values from `keys`.
 * If key is not found, replace with an empty string (or you can return the original match, see below).
 *
 * @param {string} template
 * @param {object} keys
 * @param {boolean} [returnOriginalMatch=false]
 * @returns {string}
 */
function fillPlaceholders(template, keys, returnOriginalMatch = false) {
  if (!template) return "";
  if (!keys) return template;
  return template.replace(/\{(\w+)\}/g, (match, key) => {
    if (!keys[key]) {
      return returnOriginalMatch ? match : "";
    }

    return keys[key];
  });
}

/**
 * Cleanup text using a set of regex replacements (coma before punctuation, extra spaces, etc.)
 *
 * @param {string} str
 * @param {object} config — { replacements: [ [pattern, replacement], ... ] }
 * @returns {string}
 */
function cleanupText(str, config) {
  let result = str;
  for (const [pattern, replacement] of config.replacements) {
    result = result.replace(new RegExp(pattern, "gm"), replacement);
  }
  return config.trim ? result.trim() : result;
}

/**
 * Default template processor
 *
 * @param {string} template — input string with placeholders like {key}
 * @param {object} keys — { key: 'value' }
 * @param {object} [config=defaultTemplateProcessorConfig] — Optional configuration for cleanupText
 * @returns {string}
 *
 * @example
 * processTemplate('Hello {title}, {name}!', { name: 'Neo' })
 * // => "Hello Neo!"
 */
export function processTemplate(
  template,
  keys,
  config = defaultTemplateProcessorConfig
) {
  let result = fillPlaceholders(template, keys);
  result = cleanupText(result, config);
  return result;
}
