import { spacesToDashes } from "~/common/helpers";
import { processTemplate } from "~/common/templateProcessor";

export const state = () => ({
  mobile: false,
  mobileSlug: false,

  openCommentsFilter: false,
  openPhotosFilter: false,
  openVideofilter: false,
  openCatalogFilter: false,
  openNewsFilter: false,
  openBalancesFilter: false,
  openBlackListFilter: false,
  countProfiles: [],

  currentCity: {
    id: 1,
    name: "Dubai",
  },

  currentDistrict: "",
  modalChangeCityStatus: false,

  authedAdvertiser: {},

  paddingForHiddenHeader: false,
  seoTexts: {
    title: "",
    description: "",
    h1: "",
    footer: "",
  },
  seoKeys: {},
  seoSettings: {},
  showLegend: false,
});

export const getters = {
  getCommentsModal: (state) => state.openCommentsFilter,

  getBalancesModal: (state) => state.openBalancesFilter,

  getBlackListModal: (state) => state.openBlackListFilter,

  getPhotosModal: (state) => state.openPhotosFilter,

  getNewsModal: (state) => state.openNewsFilter,

  getMobileStatus: (state) => state.mobile,

  getCurrentCity: (state) => state.currentCity,

  getCurrentDistrict: (state) => state.currentDistrict,

  getCountProfiles: (state) => state.countProfiles,

  getAuthedAdvertiser: (state) => state.authedAdvertiser,

  getPaddingForHiddenHeader: (state) => state.paddingForHiddenHeader,

  getVideoFilterModal: (state) => state.openVideofilter,

  getStatusModalChangeCity: (state) => state.modalChangeCityStatus,

  getMobileSlug: (state) => state.mobileSlug,

  getCurrentCityUrl: (state) => {
    return spacesToDashes(state.currentCity.name);
  },

  getCurrentLocationUrl: (state) => {
    const cityUrl = spacesToDashes(state.currentCity.name);
    const districtUrl = state.currentDistrict
      ? "-" + spacesToDashes(state.currentDistrict)
      : "";
    return cityUrl + districtUrl;
  },

  getSeoTexts: (state) => state.seoTexts,
  getProcessedSeoTexts: (state) => {
    const { title, description, h1, footer } = state.seoTexts;
    const keys = state.seoKeys;
    keys.city = keys.city || state.currentCity.name;
    keys.district = keys.district || state.currentDistrict;

    return {
      title: processTemplate(title, keys),
      description: processTemplate(description, keys),
      h1: processTemplate(h1, keys),
      footer: processTemplate(footer, keys),
    };
  },
  getSeoKeys: (state) => state.seoKeys,
  getSeoSettings: (state) => state.seoSettings,
  getShowLegend: (state) => state.showLegend,
};

export const mutations = {
  setMobile(state, payload) {
    state.mobile = payload;
  },

  setMobileSlug(state, payload) {
    state.mobileSlug = payload;
  },

  setModalChangeCityStatus(state) {
    state.modalChangeCityStatus = !state.modalChangeCityStatus;
  },

  setCatalogFilter(state, payload) {
    state.openCatalogFilter = payload;
  },

  setCommentsFilter(state) {
    state.openCommentsFilter = !state.openCommentsFilter;
  },

  setBalancesFilter(state) {
    state.openBalancesFilter = !state.openBalancesFilter;
  },

  setBlackListFilter(state) {
    state.openBlackListFilter = !state.openBlackListFilter;
  },

  setPhotosFilter(state) {
    state.openPhotosFilter = !state.openPhotosFilter;
  },

  setNewsFilter(state) {
    state.openNewsFilter = !state.openNewsFilter;
  },

  setCurrentCity(state, city) {
    state.currentCity = city;
  },

  setCurrentDistrict(state, district) {
    state.currentDistrict = district;
  },

  setCities(state, data) {
    state.cities = data;
  },

  setCountProfiles(state, data) {
    state.countProfiles = data;
  },

  setAuthedAdvertiser(state, data) {
    state.authedAdvertiser = data;
  },

  setPaddingForHiddenHeader(state, value) {
    state.paddingForHiddenHeader = value;
  },

  setVideoFilterModal(state) {
    state.openVideofilter = !state.openVideofilter;
  },

  setSeoTexts(state, value) {
    state.seoTexts = value;
  },

  setSeoKeys(state, value) {
    state.seoKeys = value;
  },

  setSeoSettings(state, value) {
    state.seoSettings = value;
  },

  setShowLegend(state, value) {
    state.showLegend = value;
  },
};

export const actions = {
  async retrieveLocation({ commit, rootGetters, dispatch }) {
    try {
      const response = await this.$axios.get(
        this.$config.apiURL + "/api/get_geoip"
      );
      const cityTitle = response?.data?.city;
      if (!cityTitle) return;
      if (!Object.keys(rootGetters["profiles/getFiltersList"]).length) {
        await dispatch("profiles/retrieveFilters", {}, { root: true });
      }
      for (
        let i = 0;
        i < rootGetters["profiles/getFiltersList"].cities.length;
        i++
      ) {
        const city = rootGetters["profiles/getFiltersList"].cities[i];
        if (city.title === cityTitle) {
          commit("setCurrentCity", { id: city.id, name: city.title });
          return;
        }
      }
    } catch (error) {
      commit("setCurrentCity", { id: 1, name: "Dubai" });
      console.log(error);
    }
  },

  async retrieveProfileCountsByLocations({ commit }) {
    try {
      const response = await this.$axios.get("/api/get_cities_new");

      commit("setCountProfiles", response.data);
    } catch (error) {
      commit("setCountProfiles", []);
      console.log(error);
    }
  },

  async retrieveAuthedAdvertiserData({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/account");
      console.log(data);

      commit("setAuthedAdvertiser", data);
    } catch (error) {
      commit("setAuthedAdvertiser", {});
      console.log(error);
    }
  },

  async retrieveSeoTexts({ commit }, path) {
    try {
      const skipPathParts = ["dashboard", "auth"];
      for (let i = 0; i < skipPathParts.length; i++) {
        if (path.includes(skipPathParts[i])) {
          commit("setSeoTexts", {
            title: "",
            description: "",
            h1: "",
            footer: "",
          });
          return;
        }
      }
      const pathUnslash = path.replace("/", "");
      const apiPath = pathUnslash ? pathUnslash : "main";
      const { data } = await this.$axios.get("/api/seo_text", {
        params: {
          page: apiPath,
        },
      });
      commit("setSeoTexts", {
        title: data?.title,
        description: data?.description,
        h1: data?.h1,
        footer: data?.text,
      });
      commit("setSeoSettings", {
        isShowMoreEnabled: data?.enable_show_more == 1,
        page: data?.page,
        shortHeight: data?.short_height,
        shortLineCount: data?.short_line_count,
      });
    } catch (error) {
      commit("setSeoTexts", {
        title: "",
        description: "",
        h1: "",
        footer: "",
      });
      console.log(error);
    }
  },
};
