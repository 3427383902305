import Vue from "vue";
import ImgScreen from "~/components/ui/ImgScreen.vue";

const addPluginAttributes = (el, binding, options) => {
  let cursor = "pointer";
  let group = binding.arg || null;
  let openOn;
  let sourceButton;
  let src = el.src; // eslint-disable-line prefer-destructuring
  let title;
  let titleUrl;
  let thumbnails;
  let galleryType;
  const events = {};

  if (options.altAsTitle) title = el.alt;

  /* eslint-disable prefer-destructuring */
  openOn = options.openOn;
  sourceButton = options.sourceButton;
  thumbnails = options.thumbnails;
  /* eslint-enable prefer-destructuring */

  if (typeof binding.value !== "undefined") {
    cursor = binding.value.cursor || cursor;
    group = binding.value.group || group;
    openOn = binding.value.openOn || openOn;
    src = binding.value.src || src;
    title = binding.value.title || title;
    titleUrl = binding.value.titleUrl || titleUrl;
    galleryType = binding.value.galleryType || galleryType;

    events.opened = binding.value.opened;
    events.closed = binding.value.closed;
    events.changed = binding.value.changed;

    if (binding.value.sourceButton !== undefined) {
      sourceButton = binding.value.sourceButton; // eslint-disable-line prefer-destructuring
    }
    if (binding.value.thumbnails !== undefined) {
      thumbnails = binding.value.thumbnails; // eslint-disable-line prefer-destructuring
    }
  }

  el.setAttribute("data-vue-img-src", src);

  if (group) el.setAttribute("data-vue-img-group", group);
  if (title) el.setAttribute("data-vue-img-title", title);
  if (titleUrl) el.setAttribute("data-vue-img-title-url", titleUrl);
  if (sourceButton) el.setAttribute("data-vue-img-source-button", sourceButton);
  if (thumbnails) el.setAttribute("data-vue-img-thumbnails", thumbnails);
  if (galleryType) el.setAttribute("data-vue-img-gallery-type", galleryType);

  if (!src) console.error("v-img element missing src parameter.");

  el.style.cursor = cursor; // eslint-disable-line no-param-reassign

  return {
    cursor,
    src,
    group,
    title,
    titleUrl,
    events,
    sourceButton,
    openOn,
    thumbnails,
    galleryType,
  };
};

const install = (Vue, { store, ...options }) => {
  const Screen = Vue.extend(ImgScreen);

  const defaultOptions = {
    altAsTitle: false,
    sourceButton: false,
    thumbnails: false,
    openOn: "click",
  };

  options = Object.assign(defaultOptions, options);

  Vue.directive("img", {
    update(el, binding, vnode, oldVnode) {
      let altUpdated;
      let srcUpdated;

      if (oldVnode.data.attrs && vnode.data.attrs) {
        srcUpdated = oldVnode.data.attrs.src !== vnode.data.attrs.src;
        if (options.altAsTitle) {
          altUpdated = oldVnode.data.attrs.alt !== vnode.data.attrs.alt;
        }
      }

      const bindingValueUpdated = binding.oldValue !== binding.value;

      if (srcUpdated || altUpdated || bindingValueUpdated) {
        addPluginAttributes(el, binding, options);
      }
    },

    bind(el, binding) {
      const addedAttributes = addPluginAttributes(el, binding, options);

      let vm = window.vueImg;
      if (!vm) {
        const element = document.createElement("div");
        element.setAttribute("id", "imageScreen");
        document.querySelector("body").appendChild(element);

        // Передаём Store в инстанс компонента
        vm = window.vueImg = new Screen({
          store, // Инъекция Store
        }).$mount("#imageScreen");
      }

      el.addEventListener(addedAttributes.openOn, () => {
        let images;
        if (!el.dataset.vueImgGroup) {
          images = [el];
        } else {
          images = [
            ...document.querySelectorAll(
              `img[data-vue-img-group="${el.dataset.vueImgGroup}"]`
            ),
          ];
        }
        Vue.set(
          vm,
          "images",
          images.map((e) => e.dataset.vueImgSrc)
        );
        Vue.set(
          vm,
          "titles",
          images.map((e) => e.dataset.vueImgTitle)
        );
        Vue.set(
          vm,
          "titleUrls",
          images.map((e) => e.dataset.vueImgTitleUrl)
        );
        Vue.set(
          vm,
          "galleryTypes",
          images.map((e) => e.dataset.vueImgGalleryType)
        );
        Vue.set(
          vm,
          "sourceButtons",
          images.map((e) => e.dataset.vueImgSourceButton === "true")
        );
        Vue.set(vm, "thumbnails", el.dataset.vueImgThumbnails === "true");
        Vue.set(vm, "currentImageIndex", images.indexOf(el));
        Vue.set(vm, "handlers", addedAttributes.events);
        Vue.set(vm, "closed", false);
      });
    },
  });
};

export default ({ store }) => {
  Vue.use(install, { store });
};
