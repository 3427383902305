export const state = () => ({
  requests: [],

  /**
   * profile_id, date_from, date_to, per_page, page
   *  */
  params: {
    per_page: 50,
    page: 1,
  },

  lastPage: 1,
  total: 0,
  current_page: 1,
});

export const getters = {
  getRequests: (state) => {
    return state.requests;
  },

  getParams: (state) => {
    return state.params;
  },

  getTotal: (state) => {
    return state.total;
  },

  getCurrentPage: (state) => {
    return state.current_page;
  },

  getLastPage: (state) => {
    return state.lastPage;
  },
};

export const mutations = {
  setRequests(state, requests) {
    state.requests = requests;
  },

  setRequestField(state, { id, field, value }) {
    const index = state.requests.findIndex((request) => request.id == id);
    if (index !== -1) {
      state.requests[index][field] = value;
    }
  },

  removeRequest(state, id) {
    state.requests = state.requests.filter((request) => request.id !== id);
  },

  appendRequest(state, request) {
    state.requests = [...state.requests, ...request];
  },

  setParams(state, params) {
    state.params = params;
  },

  setParam(state, { key, value }) {
    state.params[key] = value;
  },

  setTotal(state, value) {
    state.total = value;
  },

  setPageData(state, { lastPage, total, current_page }) {
    state.lastPage = lastPage;
    state.total = total;
    state.current_page = current_page;
  },
};

export const actions = {
  async fetchRequests({ commit, state }, page) {
    if (page) {
      commit("setParam", { key: "page", value: page });
    }
    try {
      const response = await this.$axios.get("/api/booking", {
        params: state.params,
      });

      if (page) {
        commit("appendRequest", response.data.data);
      } else {
        commit("setRequests", response.data.data);
      }
      commit("setPageData", {
        lastPage: response.data.last_page,
        total: response.data.total,
        current_page: response.data.current_page,
      });
    } catch (e) {
      console.error("Error fetching requests:", e);
    }
  },

  async acceptRequest({ commit }, id) {
    try {
      const response = await this.$axios.post(`/api/booking/${id}/approve`);
      if (response.data.status) {
        commit("setRequestField", { id, field: "approved", value: 1 });
      }
      return response.data;
    } catch (e) {
      console.error("Error approving request:", e);
    }
  },

  async rejectRequest({ commit, state }, id) {
    try {
      const response = await this.$axios.post(`/api/booking/${id}/delete`);
      if (response.data.status) {
        commit("removeRequest", id);
        commit("setTotal", state.total - 1);
      }
      return response.data;
    } catch (e) {
      console.error("Error rejecting request:", e);
    }
  },

  async deleteRequest({ commit, state }, id) {
    try {
      const response = await this.$axios.post(`/api/booking/${id}/delete`);
      if (response.data.status) {
        commit("removeRequest", id);
        commit("setTotal", state.total - 1);
      }
      return response.data;
    } catch (e) {
      console.error("Error rejecting request:", e);
    }
  },

  async readRequest({ commit }, id) {
    try {
      const response = await this.$axios.post(`/api/notifications/read`, {
        id,
        type: "booking",
      });

      if (response.data.status) {
        commit("setRequestField", { id, field: "viewed", value: 1 });
      }

      return response.data;
    } catch (e) {
      console.error("Error reading request:", e);
    }
  },
};
