export const state = () => ({
  /**
   * Schedule for a specific day of the week
   * - if there are no both values for the day of the week - then it will NOT be in the schedule,
   * - if there is only one value, then the missing one will be filled with from: 00:00:00 or to: 23:59:59 respectively
   *
   * {
   *	"id": 2,
   *	"profile_id": 2,
   *	"monday_time_from": "10:00:00",
   *	"monday_time_to": "14:00:00",
   *	"tuesday_time_from": "10:00:00",
   *	"tuesday_time_to": "23:59:59",
   *	"wednesday_time_from": "00:00:00",
   *	"wednesday_time_to": "16:00:00",
   *	"thursday_time_from": null,
   *	"thursday_time_to": null,
   *	"friday_time_from": null,
   *	"friday_time_to": null,
   *	"saturday_time_from": null,
   *	"saturday_time_to": null,
   *	"sunday_time_from": null,
   *	"sunday_time_to": null,
   *	"created_at": "2025-02-25T08:51:15.000000Z",
   *	"updated_at": "2025-02-25T08:51:15.000000Z"
   * }
   */
  schedule: {},
});

export const getters = {
  getSchedule: (state) => {
    return state.schedule;
  },
};

export const mutations = {
  setSchedule(state, schedule) {
    state.schedule = schedule;
  },
};

export const actions = {
  async fetchSchedule({ commit }, profileId) {
    try {
      const response = await this.$axios.get(
        `/api/profile/${profileId}/booking-week`
      );
      commit("setSchedule", response.data);
    } catch (e) {
      console.error(e);
    }
  },

  async updateSchedule({ commit }, { profileId, schedule }) {
    try {
      const response = await this.$axios.post(
        `/api/profile/${profileId}/booking-week`,
        schedule
      );
      commit("setSchedule", response.data);
    } catch (e) {
      console.error(e);
    }
    commit("setSchedule", schedule);
  },

  clearSchedule({ commit }) {
    commit("setSchedule", {});
  },
};
