import { render, staticRenderFns } from "./header-v2.vue?vue&type=template&id=8e30b996"
import script from "./header-v2.vue?vue&type=script&lang=js"
export * from "./header-v2.vue?vue&type=script&lang=js"
import style0 from "./header-v2.vue?vue&type=style&index=0&id=8e30b996&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PrivateMessModal: require('/app/components/global/private-mess-modal.vue').default})
