const MAX_ABOUT_ME_LENGTH = 150;

export const state = () => ({
  openCatalogFilter: false,
  sortBy: null,

  filters: {
    district: null,
    gender: null,
  },

  videoProfile: [],

  allVideos: [],

  page: 1,
  per_page: 20,
  lastPage: 1,
  total: 0,
});

export const getters = {
  getVideoProfile: (state) => state.videoProfile,
  getAllVideos: (state) => state.allVideos,
  getPage: (state) => state.page,
  getLastPage: (state) => state.lastPage,
  getTotal: (state) => state.total,
};

export const mutations = {
  setVideoData(state, data) {
    state.videoProfile = data;
  },

  setAllVideos(state, videos) {
    state.allVideos = videos.map((video) => {
      const description = video.profile?.description;
      if (description?.length > MAX_ABOUT_ME_LENGTH) {
        video.profile.description =
          description.slice(0, MAX_ABOUT_ME_LENGTH).replace(/\\n|\\t/g, " ") +
          "...";
        video.profile.showMoreBtn = true;
      } else {
        if (description?.length) {
          video.profile.description = description.replace(/\\n|\\t/g, " ");
        }
        video.profile.showMoreBtn = false;
      }
      return video;
    });
  },

  setFilters: (state, filtersData) => {
    for (let key in filtersData) {
      state.filters[key] = filtersData[key];
    }
  },

  setFilter: (state, payload) => {
    state.filters[payload.key] = payload.value;
  },

  resetFilters(state) {
    for (let key in state.filters) {
      state.filters[key] = null;
    }
  },

  setPage(state, page) {
    state.page = page;
  },

  setLastPage(state, lastPage) {
    state.lastPage = lastPage;
  },

  setTotal(state, total) {
    state.total = total;
  },

  setFavorite(state, payload) {
    state.allVideos.forEach((video) => {
      if (video.profile.id === payload.id) {
        video.profile.isFavorite = payload.profile.isFavorite;
      }
    });
  },
};

export const actions = {
  async authUserVideo(context, payload) {
    try {
      const formData = new FormData();
      formData.append(
        "username",
        payload.$state.user.login.replace(/\W/gi, "_")
      );
      formData.append("password", payload.$state.user.login);
      formData.append("login", "Login");

      await this.$axios.post(
        this.$config.videoUrl + "/actions/login.php",
        formData,
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async getVideo({ commit }, payload) {
    try {
      const { data } = await this.$axios.get(
        `/api/profiles/video?profile_id=${payload}`
      );

      commit("setVideoData", data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  },

  async retrieveAllVideos({ state, commit, rootGetters }, payload) {
    try {
      const city = rootGetters["global/getCurrentCity"];
      const { data } = await this.$axios.get(`/api/video`, {
        params: {
          city: city.id,
          page: payload?.page || state.page,
          gender: state.filters.gender,
          district: state.filters.district,
          per_page: state.per_page,
        },
      });

      const newVideos = payload?.reset
        ? data.videos
        : state.allVideos.concat(data.videos);

      commit("setAllVideos", newVideos);
      commit("setPage", data.page);
      commit("setLastPage", data.number_pages);
      commit("setTotal", data.total);
    } catch (error) {
      commit("setAllVideos", []);
      console.log(error);
    }
  },
};
