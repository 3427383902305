// import data from "~/static/updates.json";

export const state = () => ({
  allNews: [],
  filters: {
    city: [],
    district: [],
    gender: [],
    profile_status: [],
    newsType: "all",
  },
  codeCity: {},
  countNews: 0,
  currentPage: 0,
  nextPage: 1,
  lastPage: false,
  searchText: "",
  date_from: "",
  date_to: "",
});

export const getters = {
  getAllNews: (state) => state.allNews,

  getNumeberNews: (state) => state.countNews,

  getCurrentPage: (state) => state.currentPage,

  getLastPage: (state) => state.lastPage,

  getNextPage: (state) => state.nextPage,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setFirstNews: (state, { data: news }) => {
    console.log(news);
    state.allNews = news;
  },

  setLoadNews: (state, { data }) => {
    for (let news of data) {
      state.allNews.push(news);
    }
  },

  setCountNews: (state, count) => {
    state.countNews = count;
  },

  setLastPage: (state, status) => {
    state.lastPage = status;
  },

  setCurrentPage: (state, number) => {
    state.currentPage = number;
  },

  setNextPage: (state, number) => {
    state.nextPage = number + 1;
  },

  setFilters: (state, { filterName, value }) => {
    state.filters[filterName] = value;
  },

  setCities: (state, { cities }) => {
    state.filters.city = cities;
  },

  setDistricts: (state, { district }) => {
    state.filters.district = district;
  },

  setCityCodes: (state, payload) => {
    state.codeCity = payload;
  },

  setSearchText: (state, payload) => {
    state.searchText = payload;
  },

  setDateFilter(state, value) {
    state.date_to = "";
    state.date_from = "";

    if (value.length === 1 && value.length !== 0) {
      state.date_from = value[0];
    } else if (value.length === 2) {
      state.date_from = value[0];
      state.date_to = value[1];
    }
  },

  resetFilters(state) {
    state.filters.city = [];
    state.filters.district = [];
    state.filters.gender = [];
    state.filters.profile_status = [];
    state.filters.newsType = "all";

    delete state.filters.profile_id;

    state.date_from = "";
    state.date_to = "";
    state.searchText = "";
  },

  setFavorite(state, payload) {
    state.allNews.forEach((newsItem) => {
      if (newsItem.profile.id === payload.id) {
        newsItem.profile.isFavorite = payload.profile.isFavorite;
      }
    });
  },
};

export const actions = {
  async retrieveNews({ commit, state, rootGetters }, payload = 1) {
    try {
      const params = {
        page: payload == "next" ? state.nextPage : payload,
        profile_id: state.filters.profile_id,
        per_page: 50,
      };

      if (!state.filters.profile_id) {
        params.city =
          state.filters.city || rootGetters["global/getCurrentCity"].id;
        params.district = state.filters.district;
        params.gender = state.filters.gender;
        params.profile_status = state.filters.profile_status;
        params.newsType = state.filters.newsType;
        params.date_from = state.date_from;
        params.date_to = state.date_to ? state.date_to : state.date_from;
        params.search = state.searchText;
      }

      const data = await this.$axios.$get("/api/news", {
        params,
      });

      if (data.last_page === data.current_page) {
        commit("setLastPage", true);
      } else {
        commit("setLastPage", false);
      }

      if (payload == "next") {
        commit("setLoadNews", data);
      } else {
        commit("setFirstNews", data);
      }
      commit("setCountNews", data.total);
      commit("setCurrentPage", data.current_page);
      commit("setNextPage", data.current_page);
    } catch (error) {
      commit("setFirstNews", []);
      console.log("An error occurred while loading news");
      console.log(error);
    }
  },

  async loadCityCodes({ state, commit }) {
    try {
      const { data } = await this.$axios.get("/api/get_cities");

      commit("setCityCodes", data);
    } catch (error) {
      console.log(error);

      state.codeCity = {
        1: "Dubai",
        2: "Abu Dhabi",
      };
    }
  },

  async setSearchText({ commit }, payload) {
    commit("setSearchText", payload);
  },
};
