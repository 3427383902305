
import { mapGetters } from "vuex";
import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

export default {
  name: "Catalog",

  components: {
    HeaderV2,
    BookMeModal: () =>
      import("~/components/v2/modals/profiles/book-me-modal.vue"),
  },

  middleware: ["catalog-routes", "isActive"],

  data() {
    return {
      showPartHeight: 0,
    };
  },

  computed: {
    ...mapGetters({
      headerHidden: "global/getPaddingForHiddenHeader",
    }),
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("notifications/retrieveSidebarNotifications");
    }

    this.$root.$on("set-page-padding", () => {
      this.setPagePadding();
    });

    this.$root.$on("book-me-modal:open", (slug) => {
      this.$refs.bookMeModal.open(slug);
    });
  },

  methods: {
    valueHeightShowPart(value) {
      this.showPartHeight = value;
    },

    setPagePadding() {
      if (!this.$refs.header) return;
      const headerHeight =
        this.$refs.header.$el.querySelector("header").offsetHeight;
      const main = document.querySelector("main");
      main.style.paddingTop = `${headerHeight + 10}px`;
    },
  },
};
